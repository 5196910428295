import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import StickerView from './components/Pages/StickerView/StickerView';
// import QRPayment from './components/Pages/QRPayment/QRPayment';
import Login from './components/Pages/Login/Login';
import FoodTracker from './components/Pages/FoodTracker/FoodTracker';
import InventoryOrder from './components/Pages/InventoryOrder/InventoryOrder';
import Wastage from './components/Pages/Wastage/Wastage';
import { Provider } from 'react-redux';
import PackagingList from './components/Pages/PackagingList/PackagingList';

import store from './store';
import './App.css';
import './css/common.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <Router>
        <div className="contentWrapper">
        <Switch>
            <Route exact path="/" component={StickerView}/>
            {/* <Route exact path="/qrpayment" component={QRPayment}/> */}
            <Route exact path="/sticker" component={StickerView}/>
            {/* <Route exact path="/packaging" component={PackagingList}/>
            <Route exact path="/inventoryOrder" component={InventoryOrder}/>
            <Route exact path="/wastage" component={Wastage}/> */}
            <Route path="/login" component={Login}/>
        </Switch>
        </div>
      </Router>
      </Provider>
    </div>
  );
}

export default App;
